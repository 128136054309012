<template>
	<div class="guwen">
		<div class="guwen-a">
			<div class="">
				<div class="guwen-b">
					<!-- 找旅行顾问头 -->
					<div class="guwen-lx">找旅行顾问</div>
					<div class="guwen-c">
						<!-- 找旅行顾问头-搜索框 -->
						<div class="guwen-d">
							<el-input v-model="input" placeholder="输入顾问名字"></el-input>
						</div>
						<div class="guwen-e">快速查找</div>
					</div>
				</div>

				<div class="guwen-lva">
					<div class="guwen-lvb">
						<!-- 旅行顾问 去过地方数 服务客服数 方案数 选项卡 -->
						<div class="guwen-lvc" v-for="(item,index) in dif" :key="index"
							:class="[difng==index?'guwen-lvcc':'']" @click="btn(index)">
							<div class="">{{item.difa}}</div>
							<img :src="item.url" class="guwen-xia">
						</div>
						<div class="">
							<el-checkbox v-model="checked">只选在线</el-checkbox>
						</div>
					</div>

					<div class="guwen-lvb" >
						<!-- 旅行顾问 去过地方数 服务客服数 方案数 选项卡右边分页 -->
						<div class="guwen-lvd">1/2页</div>
						<div class="guwen-lve guwen-lvec">
							<img src="../../assets/zuojianx.jpg" class="guwen-lvee">
							<img src="../../assets/zuojiantl.jpg" class="guwen-lvef">
						</div>
						<div class="guwen-lve guwen-lved">
							<img src="../../assets/youjianx.png" class="guwen-lveh">
							<img src="../../assets/youjiantl.png" class="guwen-lveg">
						</div>
					</div>
				</div>

				<div v-loading="showLoading" class="guwen-lvf" v-for="(item,index) in Staff" :key="index">
					<div class="guwen-lvg">
						<!-- 旅行顾问选项卡内容 -->
						<el-avatar :size="90" :src="item.UserImg"></el-avatar>
						<div class="guwen-lvh">
							<img src="../../assets/xinxin.png" class="guwen-xinxin">
							<div class="">
								{{item.UserAVGScore}}
							</div>
						</div>
					</div>
					<div class="guwen-lvi">
						<!-- 旅行顾问选项卡内容 头-->
						<div class="guwen-lvj">
							<div class="guwen-lvk">
								<div class="guwen-lvl">{{item.StaffName}}</div>
								<div class="guwen-lvn">
									<div class="guwen-lvm">
										<img src="../../assets/erweimah.jpg" class="guwen-lvo">
									</div>
									<div class="guwen-lvnm">
										<div class="guwen-lvp">
											<div class="guwen-lvpp"></div>
											<div class="guwen-lvppp"></div>
											<div class="guwen-lvq">
												<img :src="item.QrcodeImg" class="guwen-lvqq">
											</div>
											<div class="">扫一扫，有需要随时联系TA</div>
										</div>
									</div>
								</div>
								<div class="guwen-lvrr guwen-lvm">
									<div class=""><img title="见习旅行顾问"
											src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
											class="guwen-lvo"></div>
								</div>
								<div class="guwen-lvr">
									<div class="guwen-lvrr guwen-lvm">
										<div class=""><img title="国际机票新星"
												src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
												class="guwen-lvo"></div>
									</div>
									<div class="guwen-lvrr guwen-lvm">
										<div class=""><img title="国际机票达人"
												src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
												class="guwen-lvo"></div>
									</div>
									<div class="guwen-lvrr guwen-lvm">
										<div class=""><img title="国内机票新星"
												src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
												class="guwen-lvo"></div>
									</div>
									<div class="guwen-lvrr guwen-lvm">
										<div class=""><img title="国内机票达人"
												src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
												class="guwen-lvo"></div>
									</div>
									<div class="guwen-lvrr guwen-lvm">
										<div class=""><img title="保险新星"
												src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
												class="guwen-lvo"></div>
									</div>
									<div class="guwen-lvrr guwen-lvm">
										<div class=""><img title="保险达人"
												src="http://static.yiqifei.com/img/emblem/level_5@30X30.PNG"
												class="guwen-lvo"></div>
									</div>
								</div>
								<div class="">
									<div class="guwen-lvs" v-show="item.OnlineStatus==1">
										<img src="../../assets/buzaixian.jpg" class="guwen-lvss">当前离线
									</div>
									<div class="guwen-lvs guwen-lvsss" v-show="item.OnlineStatus==2">
										<img src="../../assets/zaixian.jpg" class="guwen-lvss">当前在线
									</div>
								</div>
							</div>

							<div class="guwen-lvt">
								<div class="guwen-lvtt">精通领域:</div>
								<div class="guwen-lvttt">{{item.JingTong}}</div>
							</div>

							<div class="guwen-lvu">
								<div class="guwen-lvu">
									<div class="guwen-lvtt">亲身去过地方:</div>
									<div class="guwen-lvuu">{{item*88}}个</div>
								</div>
								<div class="guwen-lvu">
									<div class="guwen-lvtt">共帮助过客户:</div>
									<div class="guwen-lvuu">{{item*188}}人</div>
								</div>
								<div class="guwen-lvu">
									<div class="guwen-lvtt">旅行方案数:</div>
									<div class="guwen-lvuu">{{item*288}}次</div>
								</div>
							</div>
							<div class="guwen-lvv">"为顾客解决一切旅游方面的问题"</div>
						</div>
						<div class="guwen-lvw">
							<div class="guwen-lvx"><a href="">查看TA个人主页</a></div>
							<div class="guwen-lvy"><a href="">向TA咨询</a></div>
						</div>
						<!-- 旅行顾问选项卡内容 尾-->
					</div>
				</div>

			</div>
			<div class="guwen-ph">
				<div class="guwen-pha">
					<!-- 旅行顾问右边-推荐旅行顾问轮播图 头-->
					<div class="guwen-phb">推荐旅行顾问</div>
					<el-carousel trigger="click" height="200px" indicator-position="none">
						<el-carousel-item v-for="item in 4" :key="item">
							<div class="guwen-phc">
								<div class="guwen-phc">
									<div class="guwen-phd">
										<el-avatar :size="90"
											src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png">
										</el-avatar>
									</div>
									<div class="guwen-phe"><a href="">王楚钦</a></div>
								</div>
								<div class="guwen-phf">
									<div class="guwen-phg">
										<div class="guwen-phi">{{item*888}}</div>
										<div class="guwen-phj">帮助人数</div>
									</div>
									<div class="guwen-phh"></div>
									<div class="guwen-phg">
										<div class="guwen-phi">{{item*999}}</div>
										<div class="guwen-phj">旅行方案</div>
									</div>
									<div class="guwen-phh"></div>
									<div class="guwen-phg">
										<div class="guwen-phi">{{item*8}}</div>
										<div class="guwen-phj">去过地方</div>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
					<!-- 旅行顾问右边-推荐旅行顾问轮播图 尾-->
				</div>
				<div class="guwen-pha">
					<!-- 旅行顾问右边-去过排行榜 头-->
					<div class="guwen-phb">去过排行榜</div>
					<div class="guwen-phk">
						<div class="guwen-phl">用户名</div>
						<div class="guwen-phn">
							<div class="guwen-phm">去过目的地</div>
							<div class="guwen-pho">游记</div>
						</div>
					</div>
					<div class="guwen-php" v-for="item in [1,2,3,4,5,6,7,8,9]" :key="item">
						<div class="guwen-phq">
							<div class="guwen-phr">{{item}}</div>
							<div class="guwen-phs"><a href="">王楚钦</a></div>
						</div>
						<div class="guwen-pht">
							<div class="guwen-phu">{{item*58}}</div>
							<div class="guwen-phv">{{item*53}}</div>
						</div>
					</div>
					<!-- 旅行顾问右边-去过排行榜尾-->
				</div>
				<div class="guwen-pha">
					<!-- 旅行顾问右边-最新优惠资讯排行榜头-->
					<div class="guwen-phw">
						<div class="guwen-phz">最新优惠资讯</div>
						<div class="guwen-phx"><a href="">查看更多</a></div>
					</div>
					<div class="">2022-02-25飞报</div>
					<!-- 旅行顾问右边-最新优惠资讯排行榜尾-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');
	//接口	
	var wqgApi = require('@/api/wqgApi.js');
	//组件引用

	//公共属性
	var that;

	export default {
		components: {
			//组件

		},
		data() {
			return {
				id: '',
				title: "社区首页",
				Staff: '',
				input: '',
				checked: false,
				difng: 0,
				dif: [{
					difa: '全部',
				}, {
					difa: '去过地方数',
					url: require('../../assets/jiantx.png'),
				}, {
					difa: '服务客服数',
					url: require('../../assets/jiantx.png')
				}, {
					difa: '方案数',
					url: require('../../assets/jiantx.png')
				}],
				showLoading: false
			};
		},
		created() {
			that = this;
			GetOnlineStaffProfiles();
		},
		methods: {
			btn(index) {
				// 旅行顾问 去过地方数 服务客服数 方案数 选项卡事件
				this.difng = index
				console.log(index)
			}
		}
	};

	function GetOnlineStaffProfiles() {
		that.showLoading = true;
		var parm = {

		};
		wqgApi.GetOnlineStaffProfiles(parm, function(result) {
			that.showLoading = false;
			console.log(result);
			if (result.code == 0) {
				that.Staff = result.data.StaffContacts
				console.log(result.data.StaffContacts)
			}
		})
	}
</script>

<style lang="scss">
	@import '../../style/youji/guwen.scss';
</style>
